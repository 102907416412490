<template>
    <div>
        <component-container
            v-if="project"
            :card-title="$t('component_locationConfiguration_title')"
            :card-subtitle="$t('component_locationConfiguration_subtitle')"

            :height="480"

            :information-tooltip="{
                title: $t('component_locationConfiguration_informationTooltip_title'),
                text: $t('component_locationConfiguration_informationTooltip_text')
            }"

            :component-data="{response:{isLoaded:(project && project.isLoaded)}, hasData:true}"
        >
            <template v-slot:content>


                <v-row class="v-application mt-4" v-if="project.data">
                    <v-col cols="12">
                        <v-text-field
                            v-if="project.data.searchEngine"
                            :label="$t('common_country_label')"
                            outlined
                            dense
                            disabled
                            hide-details
                            v-model="selectedCountry"
                        />
                    </v-col>
                    <v-col cols="12" class="text-center ma-0 pa-0">
                        <v-icon large color="grey lighten-1">mdi-arrow-down-thin</v-icon>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-if="project.data.language"
                            :label="$t('common_language_label')"
                            outlined
                            dense
                            disabled
                            hide-details
                            v-model="selectedLanguage"
                        />
                    </v-col>
                    <v-col cols="12" class="text-center ma-0 pa-0">
                        <v-icon large color="grey lighten-1">mdi-arrow-down-thin</v-icon>
                    </v-col>
                </v-row>
                <v-row class="v-application" style="position: relative">

                    <div style="padding-top:80px; text-align:center;position: absolute; background-color: #e0e0e073; width: 100%; height: 100%; z-index: 999" v-if="locationLoading">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>

                    <v-col cols="12" >
                        <div style="position: relative">
                            <v-autocomplete
                                :items="displayedLocationList"
                                :label="$t('common_location_label')"
                                item-text="canonicalName"
                                item-value="id"
                                hide-details
                                :return-object="true"
                                @change="onSelectLocation"
                                ref="selectLocation"
                                class="rounded-lg"
                                :disabled="isLocationQuotaMax"
                                dense
                                outlined
                            />
                            <span :class="{'orange--text':isLocationQuotaMax,'green--text':!isLocationQuotaMax}" style="position:absolute; right:40px; top: 10px; background-color: #fff; padding-left: 5px">
                                {{ `${project.data.searchGeolocations.length}/${maxLocation}` }}
                            </span>
                        </div>

                    </v-col>
                    <v-col cols="12">
                        <div class="rounded-lg grey lighten-4 pa-4" style="border: 1px dashed #d0d0d0 !important;min-height: 66px">
                            <v-chip
                                v-for="location in project.data.searchGeolocations"
                                :key="location.id"
                                class="ma-1"
                                small
                                :close="project.data.searchGeolocations.length >1"
                                color="primary"
                                close-icon="mdi-minus-circle"
                                outlined
                                @click:close="onRemoveLocation(location)"
                                style="background-color: #fff !important;"
                            >
                                {{ (location.targetType === 'country' ? $t(`common_allCountry_label_${location.name.toLowerCase()}`) : location.name) }}
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>

            </template>
        </component-container>

        <confirm-dialog :title="$t('component_locationConfiguration_confirmDeleteTitle')" ref="confirmDeleteLocation" @confirm-dialog-valid="deleteLocations()">
            <div v-html="$t('component_locationConfiguration_confirmDeleteText')"></div>
        </confirm-dialog>
    </div>
</template>

<script>

import apiCalls from "@/api/axios/configuration/project";
import {mapGetters} from "vuex";


export default {

    data() {
        return {
            selectedCountry:null,
            selectedLanguage:null,
            maxLocation:5,
            searchEngines:[],
            searchEngineLocations:[],
            locationToDelete:null,
            locationLoading:false,
        }
    },
    components: {

    },
    computed:{
        ...mapGetters({
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
            activeSubscription:"customersManager/getActiveSubscription",
        }),

        maxKeyword(){
            return  (this.maxRequestCount()+this.additionalRequestCount()) - (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0)
        },

        displayedLocationList(){
            return this.searchEngineLocations.filter(data=>{
                return this.project.data.searchGeolocations.filter(tmp=>{ return tmp.id === data.id }).length === 0 ? data : null
            })
        },
        isLocationQuotaMax(){
            return this.project.data.searchGeolocations.length >= this.maxLocation || this.project.data.keywords.length  > this.maxKeyword
        },

    },
    methods:{
        onSelectLocation(location){
            this.$refs.selectLocation.blur()
            if(!this.isLocationQuotaMax){
                this.locationLoading = true
                apiCalls.addLocationToProject(this.$route.params.projectId, location.id).then(()=>{
                    this.initComponentData()
                })
            }
        },

        onRemoveLocation(location){
            this.locationToDelete = location
            this.$refs.confirmDeleteLocation.displayDialog()
        },
        deleteLocations(){
            if(this.locationToDelete && this.project.data.searchGeolocations.length>1){
               this.locationLoading = true
                apiCalls.deleteLocation(this.$route.params.projectId, this.locationToDelete.id).then(()=>{
                    this.initComponentData()
                })
            }
        },

        initComponentData(){

            this.getComponentData( {
                componentName:'searchEngines',
                promise: apiCalls.getSearchEngines()
            }).then(()=>{
                this.setDataComponent({
                    stateName:'projectInfo',
                    promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                    dataFormatter: this.projectData
                }).then(()=>{

                    this.setDataComponent({
                        stateName:'projectsSummary',
                        promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                    }).then(()=>{

                        apiCalls.getSearchEngineGeolocs(this.project.data.searchEngine.id).then((res)=>{
                            this.selectedCountry = this.$t(`common_countryName_for_searchEngine_${this.project.data.searchEngine.name}`)
                            this.selectedLanguage = this.$t(`common_language_label_${this.project.data.language.code}`)
                            this.searchEngineLocations = res.content

                            this.locationLoading = false
                        })
                    })
                })
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">


</style>
