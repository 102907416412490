<template>

    <div id="keywords-configuration-page">

        <div v-if="searchEngines">
            <v-row>
                <v-col cols="12">
                    <project-keyword-summary/>
                </v-col>

                <v-col cols="8">
                    <keyword-configuration tooltip-text="component_keywordConfiguration_informationTooltip_text"/>
                </v-col>
                <v-col cols="4">
                    <location-configuration/>
                </v-col>
            </v-row>

        </div>

    </div>


</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";
import KeywordConfiguration from "@/components/yooda-components/project/KeywordConfiguration";
import LocationConfiguration from "@/components/yooda-components/project/LocationConfiguration";
import ProjectKeywordSummary from "@/components/yooda-components/project/ProjectKeywordSummary";
import apiCalls from "@/api/axios/configuration/project";

export default {
    data(){
      return{
          searchEngines:null
      }
    },
    components: {
        PagePlaceholder,
        KeywordConfiguration,
        LocationConfiguration,
        ProjectKeywordSummary,
    },
    computed:{

    },
    methods:{
        initComponentData(){
            this.getComponentData( {
                componentName:'searchEngines',
                promise: apiCalls.getSearchEngines()
            })
        }
    },
    beforeMount() {
         this.initComponentData()
    },
    mounted() {
        this.analyticsIdentify()
    }
}

</script>
