<template>
    <div class="card-container">
        <vs-alert :active="true" color="primary" v-if="projectKeywordCount" style="height: 62px">
            <div class="text-center" v-html="displaySummary"></div>
            <div id="project-keywords-summary-tooltip">
                <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <yooda-icon name="info-circle"></yooda-icon>
                        </div>
                    </template>
                    <p v-html="displayProjectUsage" style="font-size: 12px; text-align: center"></p>
                </v-tooltip>
            </div>
        </vs-alert>
    </div>
</template>

<script>

import apiCalls from "@/api/axios/configuration/project";
import {mapGetters} from "vuex";
export default {

    data() {
        return {
        }
    },
    components: {

    },
    computed:{
        ...mapGetters({
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
        }),
        projectKeywordCount(){
            if(this.project.data){
                return this.project.data.keywords.length
            }
            return 0
        },
        projectGeolocationCount(){
            if(this.project.data){
                return this.project.data.searchGeolocations.length
            }
            return 0
        },
        projectRequestCount(){
            if(this.project.data){
                return this.project.data.searchGeolocations.length * this.project.data.keywords.length
            }
            return 0
        },


        displaySummary(){
            return this.$t("component_projectKeywordSummary_alert_title")
                .replace('[*TOTAL-REQUEST-COUNT*]',this.projectRequestCount)
                .replace('[*AVAILABLE-REQUEST-COUNT*]',(this.keywordUsage().total - this.keywordUsage().used))
                .replace('[*PROJECT-LOCATION-COUNT*]',this.projectGeolocationCount)
                .replace('[*PROJECT-KEYWORD-COUNT*]',this.projectKeywordCount)
        },
        displayProjectUsage(){
            return this.$t("component_projectKeywordSummary_alert_tooltip")
                .replace('[*SUBSCRIPTION-REQUEST-QUOTA*]',this.keywordUsage().total)
                .replace('[*CURRENT-PROJECT-REQUEST-COUNT*]',this.projectRequestCount)
                .replace('[*OTHER-PROJECT-REQUEST-COUNT*]',this.keywordUsage().used-(this.projectKeywordCount * this.projectGeolocationCount))
                .replace('[*REQUEST-REMAINING-COUNT*]',(this.keywordUsage().total - this.keywordUsage().used))
        }
    },
    methods:{
        keywordUsage(){
            return {
                used: (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0),
                total: this.maxRequestCount()+this.additionalRequestCount()
            }
        },
        initComponentData(){
            this.setDataComponent({
                stateName:'projectInfo',
                promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                dataFormatter: this.projectData
            }).then(()=>{
                this.setDataComponent({
                    stateName:'projectsSummary',
                    promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                })
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

#project-keywords-summary-tooltip{
    position: absolute;
    top: 32px;
    left: calc(50% + 240px);
}

</style>
